import { startCase } from 'lodash';
import { PipelineColumn } from '../../types/PipelineColumn';
import { BwicStatusLabel, OnHoverTooltip, Preloader } from '../../../common';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import {
    ArrangerPipeline,
    Category,
    categoryLabels,
    CleansingNotice,
} from '../../../../types/amr-pipeline/models/ArrangerPipeline';
import { pipelineColumnLabels, pipelineTooltipText } from '../../../../constants/amr-pipeline';
import { constants, roles, routes } from '../../../../constants';
import IconSVG from '../../../../styles/svg-icons';
import { dateTimeUtils, formatUtils, isRequesting } from '../../../../utils';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IssuanceMonitorTab } from '../../types/PipelineTabTypes';
import { equityTitles } from '../../../../constants/amr-pipeline/equity';
import { Equity } from '../../../../types/amr-pipeline/enums/Equity';
import { FilterRelatedTransactionsIcon } from '../../common/FilterRelatedTransactionsIcon';
import { ManagerLink } from '../../common/ManagerLink';
import { ImSubscriptionActionBlocker } from '../../subscription/ImSubscriptionActionBlocker';
import { BankLink } from '../../common/BankLink';
import DownloadDocument from '../../common/documents/DownloadDocument';
import moment from 'moment';
import { PeriodPopover } from '../PeriodPopover';
import { BwicProcessType } from '../../../../types/models/Process';
import { BwicMonitorLookupLink } from '../../../common/BwicMonitorLookupLink';
import { MyBwicIcon } from '../../../common/MyBwicIcon';
import { SecurityLastTradedDate } from '../../../common/SecurityLastTradedDate';
import { SecurityInventoryLink } from '../../../inventory/SecurityInventoryLink';
import { user } from '../../../../user';
import { ListBuilder } from '../../../../utils/ListBuilder';
import { Link } from 'react-router-dom';
import { Color } from '../../../common/Color';
import { DealSecurity, DealSecurityStatistics } from '../../../../types/security/DealSecurityStatistics';
import { isinCusip } from '../../../../types/security/Security';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ViewDealDocumentsButton } from '../../../documents/deal-documents-popup/ViewDealDocumentButton';
import { RequestState } from '../../../../constants/request-state';
import { DealDocumentsPopupTab } from '../../../documents/deal-documents-popup/DealDocumentsPopup';

export const lastUpdatedColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.lastUpdated,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.lastUpdated],
    renderColumnContent: function (arrangerPipeline) {
        if (arrangerPipeline.category === Category.CleansingNotice) {
            const value = moment(arrangerPipeline.lastUpdated).utc().format(constants.dateFormat);
            return <OnHoverTooltip overlay={value}>{value}</OnHoverTooltip>;
        }


        const dateUpdatedEst = dateTimeUtils.changeDateTimeZone(
            arrangerPipeline.lastUpdated,
            constants.estTimezone,
            false,
        );

        const value = dateUpdatedEst.format(constants.dateFormat);

        const tooltipOverlay = `${dateUpdatedEst.format(constants.dateTimeFormat)} EST`;

        return <OnHoverTooltip overlay={tooltipOverlay}>{value}</OnHoverTooltip>;
    },
    sortingField: PipelineColumn.lastUpdated,
    className: 'data-list-cell-sm',
};

const arrangerColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.arranger,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.arranger],
    renderColumnContent: arrangerPipeline => {
        const { arranger } = arrangerPipeline;

        if (!arranger) {
            return constants.emptyPlaceholder;
        }

        return (
            <BankLink
                legalName={arranger.code ?? arranger.legalName}
                referenceName={arranger.referenceName}
                overlayText={arranger.legalName}
            />
        );
    },
    sortingField: PipelineColumn.arranger,
};

const toAllArrangersColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toAllArrangers,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toAllArrangers],
    renderColumnContent: (arrangerPipeline, context) => {
        const { setTab } = context;
        const { arranger } = arrangerPipeline;

        if (!arranger) {
            return null;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked =>
                    blocked ? (
                        <button className="btn-link" disabled>
                            <IconSVG name="go-to" width={16} height={16} />
                        </button>
                    ) : (
                        <OnHoverTooltip overlay="All Arranger’s Transactions">
                            <Link
                                target="_self"
                                to={`${routes.AMRPipeline}/?arrangers=${arranger.referenceName}`}
                                className="btn-link"
                                onClick={() => setTab(IssuanceMonitorTab.Deals)}
                            >
                                <IconSVG name="go-to" width={16} height={16} />
                            </Link>
                        </OnHoverTooltip>
                    )
                }
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs',
};

export const dealNameColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.dealName,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealName],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dealLegalName } = arrangerPipeline;

        return (
            <div>
                <div className="text-ellipsis name-box">
                    <OnHoverTooltip overlay={dealLegalName}>
                        <span>{dealLegalName}</span>
                    </OnHoverTooltip>
                </div>
            </div>
        );
    },
    sortingField: PipelineColumn.dealName,
    className: 'data-list-cell-xl',
};

const toRelatedDealsColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toRelatedDeals,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toRelatedDeals],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dealLegalName, dealReferenceName, numberOfTransactions } = arrangerPipeline;
        const { setTab } = context;

        if (!numberOfTransactions) {
            return null;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked => (
                    <FilterRelatedTransactionsIcon
                        disabled={blocked}
                        dealLegalName={dealLegalName}
                        dealReferenceName={dealReferenceName}
                        onClick={() => setTab(IssuanceMonitorTab.Deals)}
                    />
                )}
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs',
};

export const managerColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.manager,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
    renderColumnContent: (arrangerPipeline, context) => {
        const { onManagerClick } = context;
        const { collateralManager } = arrangerPipeline;

        if (!collateralManager) {
            return constants.emptyPlaceholder;
        }

        return (
            <ManagerLink
                referenceName={collateralManager.referenceName}
                legalName={collateralManager.legalName}
                onClick={onManagerClick}
            />
        );
    },
    sortingField: 'collateralManager',
    className: 'data-list-cell-xl',
};

const toAllManagersColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toAllManagers,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toAllManagers],
    renderColumnContent: (arrangerPipeline, context) => {
        const { setTab } = context;
        const { collateralManager } = arrangerPipeline;

        if (!collateralManager) {
            return constants.emptyPlaceholder;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked =>
                    blocked ? (
                        <button className="btn-link" disabled>
                            <IconSVG name="go-to" width={16} height={16} />
                        </button>
                    ) : (
                        <OnHoverTooltip overlay="All Manager’s Transactions">
                            <Link
                                target="_self"
                                to={`${routes.AMRPipeline}/?collateralManagers=${collateralManager.referenceName}`}
                                className="btn-link"
                                onClick={() => setTab(IssuanceMonitorTab.Deals)}
                            >
                                <IconSVG name="go-to" width={16} height={16} />
                            </Link>
                        </OnHoverTooltip>
                    )
                }
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs',
};

export const transactionTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.transactionType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionType],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.type ? startCase(arrangerPipeline.type) : constants.emptyPlaceholder,
    sortingField: PipelineColumn.transactionType,
};

export const collateralTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.collateralType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.collateralType],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.collateralType
            ? formatUtils.formatCollateralType(arrangerPipeline.collateralType)
            : constants.emptyPlaceholder,
    sortingField: PipelineColumn.collateralType,
    className: 'data-list-cell-xs',
};

export const nonCallPeriodEndYrsColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.nonCallPeriodEndYears,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears],
    renderColumnContent: arrangerPipeline => {
        if (!arrangerPipeline.nonCallPeriodEnd) {
            return constants.emptyPlaceholder;
        }
        const years = formatUtils.formatDecimal(arrangerPipeline.nonCallPeriodEnd);
        return <span>{!!Number(years) ? years : constants.emptyPlaceholder}</span>;
    },
    sortingField: PipelineColumn.nonCallPeriodEnd,
    headerClassName: 'text-right',
    bodyClassName: 'text-right popover-column',
};
export const reinvestmentPeriodEndYrsColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.reinvestmentPeriodEndYears,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears],
    renderColumnContent: arrangerPipeline => {
        if (!arrangerPipeline.reinvestmentPeriodEnd) {
            return constants.emptyPlaceholder;
        }
        const years = formatUtils.formatDecimal(arrangerPipeline.reinvestmentPeriodEnd);
        return <span>{!!Number(years) ? years : constants.emptyPlaceholder}</span>;
    },
    sortingField: PipelineColumn.reinvestmentPeriodEnd,
    headerClassName: 'text-right',
    bodyClassName: 'text-right popover-column',
};

export const expectedTimingColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.expectedTiming,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.expectedTiming],
    renderColumnContent: arrangerPipeline => arrangerPipeline.expectedTiming ?? constants.emptyPlaceholder,
    sortingField: PipelineColumn.expectedTiming,
};

export const dealStageColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.dealStage,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealStage],
    renderColumnContent: arrangerPipeline => arrangerPipeline.dealStage ?? constants.emptyPlaceholder,
    className: 'data-list-cell-sm',
    sortingField: PipelineColumn.dealStage,
};

export const euComplianceColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.euCompliance,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.euCompliance],
    renderColumnContent: arrangerPipeline => formatUtils.formatBooleanWithPlaceholder(arrangerPipeline.euCompliance),
    className: 'data-list-cell-sm',
    sortingField: PipelineColumn.euCompliance,
};

export const anchorColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.anchor,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay="‘Yes’ - looking for an investor">
            {pipelineColumnLabels[PipelineColumn.anchor]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: arrangerPipeline => formatUtils.formatBooleanWithPlaceholder(arrangerPipeline.anchor),
    className: 'data-list-cell-md',
    sortingField: PipelineColumn.anchor,
};

export const equityColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.equity,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay="‘Yes’ - looking for an investor">
            {pipelineColumnLabels[PipelineColumn.equity]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.equity && arrangerPipeline.equity !== Equity.No
            ? equityTitles[arrangerPipeline.equity]
            : constants.emptyPlaceholder,
    className: 'data-list-cell-lg',
    sortingField: PipelineColumn.equity,
};

const syndicateColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.syndicate,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.syndicate],
    renderColumnContent: (arrangerPipeline, context) => {
        const { setSyndicateContacts } = context;
        const numberOfContacts = arrangerPipeline.syndicateContacts?.length;
        return numberOfContacts ? (
            <span
                className="text-ellipsis pseudo-link"
                onClick={e => {
                    e.stopPropagation();
                    setSyndicateContacts({
                        arrangerName: arrangerPipeline.arranger?.legalName,
                        contacts: arrangerPipeline.syndicateContacts,
                    });
                }}
            >
                {numberOfContacts} contact
                {numberOfContacts > 1 ? 's' : ''}
            </span>
        ) : (
            constants.emptyPlaceholder
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
    sortingField: PipelineColumn.syndicate,
};

export const managerPresentationFileColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.managerPresentationFile,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.managerPresentationFile],
    renderColumnContent: (
        arrangerPipeline,
        { isManagerPresentationDownloading, managerPresentationLoadHandler, managerPresentationAccessType },
    ) => {
        if (!arrangerPipeline.managerPresentationFile) {
            return constants.emptyPlaceholder;
        }

        return (
            <DownloadDocument
                isLoading={isManagerPresentationDownloading}
                loadingText
                onClick={() =>
                    managerPresentationLoadHandler(
                        arrangerPipeline.referenceName,
                        arrangerPipeline.managerPresentationFile.name,
                        managerPresentationAccessType,
                    )
                }
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />
                Download
            </DownloadDocument>
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
};

export const disclosureFileColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.disclosureFile,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.disclosureFile],
    renderColumnContent: (arrangerPipeline, { disclosureLoadHandler, isDisclosureDownloading, bankReferenceName }) => {
        return arrangerPipeline.disclosureFile ? (
            <DownloadDocument
                isLoading={isDisclosureDownloading}
                loadingText
                onClick={() =>
                    disclosureLoadHandler(
                        arrangerPipeline.referenceName,
                        arrangerPipeline.disclosureFile.name,
                        bankReferenceName,
                    )
                }
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />
                Download
            </DownloadDocument>
        ) : (
            constants.emptyPlaceholder
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
};

const categoryColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.category,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.category],
    renderColumnContent: arrangerPipeline => categoryLabels[arrangerPipeline.category],
    sortingField: PipelineColumn.category,
    className: "data-list-cell-sm"
};

const tickerColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.dealTicker,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealTicker],
    renderColumnContent: arrangerPipeline => arrangerPipeline.dealTicker ?? constants.emptyPlaceholder,
    sortingField: PipelineColumn.dealTicker,
    className: "data-list-cell-lg"
};

const downloadDocumentsColumn: IColumnDefinition<
    ArrangerPipeline,
    { securities?: DealSecurityStatistics[]; securitiesRequestState: RequestState }
> = {
    columnKey: 'download-documents',
    renderColumnHeaderContent: () => 'Docs',
    renderColumnContent: (arrangerPipeline, context) => {
        if (isRequesting(context.securitiesRequestState) && arrangerPipeline.hasDocuments === undefined) {
            return <Preloader inProgress={true} small={true} />;
        }

        const tab = arrangerPipeline.category === Category.CleansingNotice ? DealDocumentsPopupTab.CleansingNotice : DealDocumentsPopupTab.OmAndIndenture;

        const security =
            arrangerPipeline.hasDocuments && arrangerPipeline.dealTicker
                ? context.securities?.find(s => s.ticker === arrangerPipeline.dealTicker)
                : null;

        const firstSecurity = security?.classes[0];
        const isinCusipValue = firstSecurity ? isinCusip(firstSecurity) : undefined;

        if (!firstSecurity) {
            return constants.emptyPlaceholder;
        }

        return (
            <div onClick={e => e.stopPropagation()}>
                <ViewDealDocumentsButton
                    className="text-regular"
                    security={{ ...firstSecurity, isinCusip: isinCusipValue }}
                    requiredFeature={SubscriptionFeature.BwicMonitorHistoricalData}
                    openTab={tab}
                />
            </div>
        );
    },
    className: 'data-list-cell-download-deal-documents',
};

const currencyColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.currency,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.currency],
    renderColumnContent: arrangerPipeline => arrangerPipeline.currencyCode ?? constants.emptyPlaceholder,
    sortingField: 'currencyCode',
    className: "data-list-cell-xs"
};

const nonCallPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.nonCallPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEnd],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.nonCallPeriodEndDate
            ? moment(arrangerPipeline.nonCallPeriodEndDate).format(constants.dateFormat)
            : constants.emptyPlaceholder,
    sortingField: 'nonCallPeriodEndDate',
    className: "data-list-cell-sm"
};

const reinvestmentPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.reinvestmentPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEnd],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.reinvestmentPeriodEndDate
            ? moment(arrangerPipeline.reinvestmentPeriodEndDate).format(constants.dateFormat)
            : constants.emptyPlaceholder,
    sortingField: 'reinvestmentPeriodEndDate',
    className: "data-list-cell-sm"
};

const outOfRIColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.outOfRI,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
            {pipelineColumnLabels[PipelineColumn.outOfRI]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: ({ reinvestmentPeriodEndDate, nonCallPeriodEndDate, outOfRI }) => {
        const columnText = outOfRI == null ? constants.emptyPlaceholder : formatUtils.formatBoolean(outOfRI);
        return (
            <OnHoverTooltip
                overlay={
                    <PeriodPopover reinvestmentPeriodEnd={reinvestmentPeriodEndDate} nonCallPeriodEnd={nonCallPeriodEndDate} />
                }
            >
                {columnText}
            </OnHoverTooltip>
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm popover-column text-capitalize',
    sortingField: PipelineColumn.outOfRI,
};

const outOfNCColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.outOfNC,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
            {pipelineColumnLabels[PipelineColumn.outOfNC]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: ({ reinvestmentPeriodEndDate, nonCallPeriodEndDate, outOfNC }) => {
        const columnText = outOfNC == null ? constants.emptyPlaceholder : formatUtils.formatBoolean(outOfNC);
        return (
            <OnHoverTooltip
                overlay={
                    <PeriodPopover reinvestmentPeriodEnd={reinvestmentPeriodEndDate} nonCallPeriodEnd={nonCallPeriodEndDate} />
                }
            >
                {columnText}
            </OnHoverTooltip>
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm popover-column text-capitalize',
    sortingField: PipelineColumn.outOfNC,
};

const vintageColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.vintage,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.vintage],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.vintage
            ? moment(arrangerPipeline.vintage).year()
            : constants.emptyPlaceholder,
    sortingField: PipelineColumn.vintage,
};

const trusteeColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.trustee,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trustee],
    renderColumnContent: ({ trustee }) =>
        trustee ? (
            <OnHoverTooltip overlay={trustee?.legalName}>{trustee?.legalName}</OnHoverTooltip>
        ) : (
            constants.emptyPlaceholder
        ),
    headerClassName: 'data-list-cell-xl',
    bodyClassName: 'data-list-cell-xl',
    sortingField: PipelineColumn.trustee,
};

const amrDealColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.amrDeal,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.amrDeal],
    renderColumnContent: ({ amrDeal }) => formatUtils.formatBooleanWithPlaceholder(amrDeal),
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
    sortingField: PipelineColumn.amr,
};

const esgColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.esg,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.esg],
    renderColumnContent: ({ esg }) => formatUtils.formatBooleanWithPlaceholder(esg),
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
    sortingField: PipelineColumn.esg,
    className: "data-list-cell-xs"
};

const staticDealColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.staticDeal,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.static],
    renderColumnContent: ({ staticDeal }) => formatUtils.formatBooleanWithPlaceholder(staticDeal),
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
    sortingField: PipelineColumn.staticDeal,
    className: "data-list-cell-sm"
};

const actionsColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: 'actions',
    renderColumnHeaderContent: () => 'Actions',
    renderColumnContent: (arrangerPipeline, context) => {
        const { onDeleteClick } = context;
        return arrangerPipeline.category === Category.CleansingNotice ? (
            <ImSubscriptionActionBlocker>
                {blocked => (
                    <button
                        className="btn-link btn-danger"
                        disabled={blocked}
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteClick(arrangerPipeline)
                        }}
                    >
                        <IconSVG name="basket" width={16} height={16} className="margin-r-0" />
                    </button>
                )}
            </ImSubscriptionActionBlocker>
        ) : (
            <span className="action-empty-placeholder display-inline">
                {constants.emptyPlaceholder}
            </span>
        );
    },
    className: 'data-list-cell-xs text-right',
    stickRight: true,
};

const columns: IColumnDefinition<ArrangerPipeline>[] = [
    lastUpdatedColumn,
    categoryColumn,
    arrangerColumn,
    toAllArrangersColumn,
    dealNameColumn,
    toRelatedDealsColumn,
    tickerColumn,
    downloadDocumentsColumn,
    managerColumn,
    toAllManagersColumn,
    transactionTypeColumn,
    collateralTypeColumn,
    currencyColumn,
    nonCallPeriodEndColumn,
    nonCallPeriodEndYrsColumn,
    outOfNCColumn,
    reinvestmentPeriodEndColumn,
    reinvestmentPeriodEndYrsColumn,
    outOfRIColumn,
    expectedTimingColumn,
    dealStageColumn,
    euComplianceColumn,
    anchorColumn,
    equityColumn,
    vintageColumn,
    trusteeColumn,
    amrDealColumn,
    esgColumn,
    staticDealColumn,
    syndicateColumn,
];

export const myBwicColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'is-my-bwic',
    renderColumnHeaderContent: () => '',
    renderColumnContent: x => x.security?.currentBwic ? (
        <MyBwicIcon bwic={x.security.currentBwic} />
    ) : null,
    className: 'data-list-cell-xxs',
}

export const currentBwicColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'current-bwic',
    renderColumnHeaderContent: () => 'Current BWIC',
    renderColumnContent: x => x.security?.currentBwic ? (
        <div className="flex-row">
            <BwicStatusLabel
                status={x.security.currentBwic.status}
                liveBidding={x.security.currentBwic.process?.type === BwicProcessType.Live}
                isParsed={x.security.currentBwic.isParsed}
                directBidding={x.security.currentBwic.isClearingBankParticipant}
                isAllToAll={x.security.currentBwic.isAllToAll}
                bwicTypeIconVisible={false}
            />
            <div className="margin-l-8">
                <BwicMonitorLookupLink ticker={x.security.ticker}
                    text={dateTimeUtils.utcToLocalString(x.security.currentBwic?.bidsDueUtc)}
                />
            </div>
        </div>
    ) : constants.emptyPlaceholder,
    className: 'data-list-cell-xl-xxl'
}

export const inventoryColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'inventory',
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay="Click on ‘View’ to see this security in the Dealer Inventory">
            Inventory
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: x =>
        !!x.security && x.security?.inventoryCount ? (
            <SecurityInventoryLink
                count={x.security.inventoryCount}
                ticker={x.security.ticker}
            />
        ) : (
            constants.emptyPlaceholder
        ),
    className: 'data-list-cell-sm-01',
}

export const lastTradedMineColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'lastTraderIsMine',
    renderColumnHeaderContent: () => '',
    renderColumnContent: x => (
        <SecurityLastTradedDate
            inventoryLastTraded={x.security?.inventoryLastTradeDate}
            bwicLastTradedDate={x.security?.lastTraded?.bidsDueUtc}
        >
            {isInventoryDate =>
                x.security?.lastTraded && !isInventoryDate ? <MyBwicIcon bwic={x.security?.lastTraded} /> : null
            }
        </SecurityLastTradedDate>
    ),
    className: 'data-list-cell-xxs',
}

export const lastTradedColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'last-traded',
    renderColumnHeaderContent: () => 'Last Traded',
    renderColumnContent: x =>
        <SecurityLastTradedDate inventoryLastTraded={x.security?.inventoryLastTradeDate} bwicLastTradedDate={x.security?.lastTraded?.bidsDueUtc}>
            {(isInventoryDate, lastTradeDate) => lastTradeDate ? moment(lastTradeDate).format(constants.dateFormat) : constants.emptyPlaceholder}
        </SecurityLastTradedDate>,
    className: 'data-list-cell-sm-01',
}

export const lastTradedColorColumn: IColumnDefinition<{ security?: DealSecurity }> = {
    columnKey: 'last-traded-color',
    renderColumnHeaderContent: () => '',
    renderColumnContent: x => (
        <SecurityLastTradedDate inventoryLastTraded={x.security?.inventoryLastTradeDate} bwicLastTradedDate={x.security?.lastTraded?.bidsDueUtc}>
            {
                isInventoryDate =>
                    x.security?.lastTraded?.color && !isInventoryDate
                        ? <Color color={x.security?.lastTraded?.color} />
                        : null
            }
        </SecurityLastTradedDate>),
    className: 'data-list-cell-md'
}

const cleansingNoticeColumns: IColumnDefinition<CleansingNotice>[] = [
    {
        columnKey: 'className',
        renderColumnHeaderContent: () => 'Class',
        renderColumnContent: cn => cn.className || constants.emptyPlaceholder,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'ticker',
        renderColumnHeaderContent: () => 'Ticker (144A)',
        renderColumnContent: cn => cn.ticker144A || constants.emptyPlaceholder,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'cusip',
        renderColumnHeaderContent: () => 'Identifier 1 (144A)',
        renderColumnContent: cn => cn.cusip144A || constants.emptyPlaceholder,
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'isin',
        renderColumnHeaderContent: () => 'Identifier 2 (144A)',
        renderColumnContent: cn => cn.isin144A || constants.emptyPlaceholder,
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'tickerRegS',
        renderColumnHeaderContent: () => 'Ticker (Reg S)',
        renderColumnContent: cn => cn.tickerRegS || constants.emptyPlaceholder,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'cusipRegS',
        renderColumnHeaderContent: () => 'Identifier 1 (Reg S)',
        renderColumnContent: cn => cn.cusipRegS || constants.emptyPlaceholder,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'isinRegS',
        renderColumnHeaderContent: () => 'Identifier 2 (Reg S)',
        renderColumnContent: cn => cn.isinRegS || constants.emptyPlaceholder,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'tickerAccdInvCertif',
        renderColumnHeaderContent: () => 'Ticker (Acc’d Inv./Certif.)',
        renderColumnContent: cn => cn.tickerAccd || constants.emptyPlaceholder,
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'cusipAccdInvestor',
        renderColumnHeaderContent: () => 'Identifier 1 (Acc’d Inv./Certif.)',
        renderColumnContent: cn => cn.cusipAccd || constants.emptyPlaceholder,
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'isinAccdInvestor',
        renderColumnHeaderContent: () => 'Identifier 2 (Acc’d Inv./Certif.)',
        renderColumnContent: cn => cn.isinAccd || constants.emptyPlaceholder,
        className: 'data-list-cell-lg-02',
    },
    myBwicColumn,
    currentBwicColumn,
    inventoryColumn,
    lastTradedMineColumn,
    lastTradedColumn,
    lastTradedColorColumn
];

const cleansingNoticesActionColumn: IColumnDefinition<CleansingNotice> = {
    columnKey: 'actionsCN',
    renderColumnHeaderContent: () => '',
    renderColumnContent: () => {
        return (
            <span className="action-empty-placeholder display-inline">
                {constants.emptyPlaceholder}
            </span>
        );
    },
    className: 'data-list-cell-xs text-right',
    stickRight: true,
}

export const getArrangerPipelineColumns = () => {
    const isMedia = user.hasRoles(roles.Media);
    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);

    const visibleColumns = new ListBuilder<IColumnDefinition<ArrangerPipeline>>()
        .add(...columns)
        .addWhen(() => !isMedia, managerPresentationFileColumn, disclosureFileColumn)
        .addWhen(() => isAdminOrDataEntry, actionsColumn)
        .result();


    return visibleColumns.map(c => new ColumnBuilder(c));
};

export const getCleansingNoticeColumns = () => {
    return cleansingNoticeColumns.map(c => new ColumnBuilder(c));
};

export const getCleansingNoticeStickyColumns = () => {
    return [[], cleansingNoticeColumns.map(c => new ColumnBuilder(c)), [new ColumnBuilder(cleansingNoticesActionColumn)]];
}
