import { useSelector } from 'react-redux';
import { arrangerPipelineSelector } from '../../../../selectors/amr-pipeline.selector';
import { SummaryRow } from '../../../common/summary-row/SummaryRow';
import { SummaryRowItem } from '../../../common/summary-row/SummaryRowItem';
import { SummaryRowGroup } from '../../../common/summary-row/SummaryRowGroup';

export default function ArrangerPipelineSummary() {
    const {
        deals: { isSummaryRequesting, summary },
    } = useSelector(arrangerPipelineSelector);

    return (
        <SummaryRow isRequesting={isSummaryRequesting}>
            <SummaryRowItem label="Cleansing Notices" value={summary?.numberOfCleansingNotices?.toLocaleString()} />
            <SummaryRowItem label="Arranger Pipelines" value={summary?.numberOfArrangerPipelines?.toLocaleString()} />
            <SummaryRowItem label="Arrangers" value={summary?.numberOfArrangers?.toLocaleString()} />
            <SummaryRowItem label="Managers" value={summary?.numberOfManagers?.toLocaleString()} />
            <SummaryRowGroup groupName="Type">
                <SummaryRowItem label="New Issue" value={summary?.numberOfNewIssueTransactions?.toLocaleString()} />
                <SummaryRowItem label="Refi" value={summary?.numberOfRefiTransactions?.toLocaleString()} />
                <SummaryRowItem label="Reset" value={summary?.numberOfResetTransactions?.toLocaleString()} />
                <SummaryRowItem label="TBD" value={summary?.numberOfTbdTypeTransactions?.toLocaleString()} />
            </SummaryRowGroup>
            <SummaryRowGroup groupName="Collateral Type">
                <SummaryRowItem label="BSL" value={summary?.numberOfBslTransactions?.toLocaleString()} />
                <SummaryRowItem label="MM" value={summary?.numberOfMmTransactions?.toLocaleString()} />
                <SummaryRowItem label="TBD" value={summary?.numberOfTbdCollatTransactions?.toLocaleString()} />
            </SummaryRowGroup>
        </SummaryRow>
    );
}
